import React, { useEffect, useState } from 'react';
import { hasConnection, createConnection } from '../lib/GraphQL';
import * as microsoftTeams from '@microsoft/teams-js';
import { Alert } from '@fluentui/react-northstar';
import axios from 'axios';
import { appConfig } from '../appConfig';
var Connection = function (props) {
    var _a = useState(false), hasConnectionState = _a[0], setHasConnection = _a[1];
    var _b = useState(true), alreadyLinkedState = _b[0], setAlreadyLinkedState = _b[1];
    useEffect(function () {
        hasConnection(props.accessToken)
            .then(function (result) {
            if (result.success) {
                setHasConnection(result.hasConnection);
            }
        });
        checkIfExists();
    }, [props.accessToken, hasConnectionState, alreadyLinkedState]);
    var content = (React.createElement("div", null));
    var checkIfExists = function () {
        axios.get(appConfig.authUrl + "/api/v1/user/email-exists/" + props.msContext.loginHint)
            .then(function (response) {
            var identities = response.data;
            var teamsIdentity = identities.identities.find(function (identity) { return identity.provider === 'ms-teams'; });
            setAlreadyLinkedState(teamsIdentity ? true : false);
        })
            .catch(function () {
            setAlreadyLinkedState(false);
        });
    };
    var createConnectionHandler = function () {
        var _a;
        if ((_a = props.msContext) === null || _a === void 0 ? void 0 : _a.tid) {
            createConnection(props.accessToken, props.msContext.tid)
                .then(function (result) {
                if (result.success) {
                    setHasConnection(true);
                }
                else {
                    console.error(JSON.stringify(result.errors));
                }
            });
        }
    };
    var createUserAssociation = function () {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/azurelogin",
            height: 800,
            width: 600,
            successCallback: function (result) {
                props.setAccessTokensState(JSON.parse(result.accessTokens));
            },
            failureCallback: function (reason) {
                // TODO handle failure scenario
            }
        });
    };
    if (!!!hasConnectionState && props.accessToken.tokenPayload.is_owner) {
        if (props.accessToken.tokenPayload.is_owner) {
            content = (React.createElement(Alert, { content: "You can enable hassle free login by creating a connection between Teams and your ScreenCloud organisation", dismissible: true, info: true, actions: [
                    {
                        content: 'Connect',
                        onClick: createConnectionHandler
                    }
                ] }));
        }
    }
    else if (!!!alreadyLinkedState) {
        content = (React.createElement(Alert, { content: "You can associate your Microsoft account with ScreenCloud to enable autologin", dismissible: true, info: true, actions: [
                {
                    content: 'Link account',
                    onClick: createUserAssociation
                }
            ] }));
    }
    return content;
};
export default Connection;
